<template>
  <ui-modal
    v-bind="$attrs"
    :header="$t('modal.preferredLocations.locationsSubtitle')"
    :open="isOpen"
    data-test-id="preferred-locations-modal"
    @close="() => $emit('on:close-modal')"
  >
    <div slot="body">
      <div
        v-if="!size(emptyPreferredLocations)"
        class="my-2 emobg-font-small emobg-color-ink-light"
      >
        {{ $t('modal.preferredLocations.all_locations_saved_placeholder') }}
      </div>
      <div
        v-for="location in emptyPreferredLocations"
        :key="location.type"
        class="my-2 row align-items-center"
      >
        <ui-location-input
          :ref="`preferred-location-${location.type}`"
          :value="location.address_street"
          :name="location.type"
          :label="labels[location.type]"
          :gkey="googleMapsKey"
          :error-messages="errorMessages"
          :custom="defaultPredictions"
          :placeholder="labels[location.type]"
          :disabled="isLoading"
          :data-test-id="`preferred-location-${location.type}-input`"
          class="col"
          consent
          @changeplace="({ detail }) => changeLocation(detail, location.type)"
        />
      </div>
      <div class="mt-4">
        <span clas="emobg-font-medium">
          {{ $t('modal.preferredLocations.saved_locations_subtitle') }}
        </span>

        <hr>

        <div
          v-if="!size(filledPreferredLocations)"
          class="my-2 emobg-font-small emobg-color-ink-light"
        >
          {{ $t('modal.preferredLocations.none_locations_saved_placeholder') }}
        </div>
        <div
          v-for="location in filledPreferredLocations"
          :key="location.type"
          class="d-flex align-items-center my-4"
        >
          <ui-text-input
            :value="location.address_street"
            :name="`preferred-locations-${location.type}-filled`"
            :data-test-id="`preferred-location-${location.type}-input-filled`"
            :icon-left="inputIcons[location.type]"
            class="w-100 mr-2"
            disabled
          />
          <ui-button
            :disabled="isLoading"
            :face="FACES.outline"
            :color="GRAYSCALE.inkLight"
            :data-test-id="`delete-preferred-location-${location.type}-button`"
            @clickbutton="clearPreferredLocation(location.type)"
          >
            <ui-icon
              :icon="ICONS.trash"
              :color="GRAYSCALE.inkLight"
            />
          </ui-button>
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end"
    >
      <ui-button
        :face="FACES.outline"
        :disabled="isLoading"
        class="mr-3"
        data-test-id="close-modal"
        @clickbutton="closeModal"
      >
        {{ $t('buttons.close') }}
      </ui-button>
      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="isSaveButtonDisabled"
        :loading="isLoading"
        data-test-id="save-preferred-locations"
        @clickbutton="saveFavoriteLocation"
      >
        {{ $t('buttons.save') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
<script>
import map from 'lodash/map';
import size from 'lodash/size';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';

import { userData } from '@/stores/User/UserData/UserDataMapper';
import { operatorLocations } from '@/stores/OperatorLocations/OperatorLocationsMapper';

import OperatorStationIcon from '@/assets/images/icons/parking-circle.svg';

import Segment from '@/mixins/Segment';
import { SEGMENT_EVENTS } from '@/vue/constants';
import cfg from '@/config';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'PreferredLocationsModal',
  mixins: [
    Segment,
  ],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    currentFavouriteLocations: {
      type: Object,
      default: () => ({}),
    },
    gmapApi: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },

  data() {
    return {
      googleMapsKey: cfg.data.googleMapsKey,
      preferredLocations: {
        work: {},
        home: {},
        custom: {},
      },
      areLocationsUpdated: false,
    };
  },
  computed: {
    operatorLocations,
    userData,
    defaultPredictions() {
      return get(this.userData, 'company.dedicated_fleet_cs_operator')
        ? this.stations
        : [];
    },

    stations() {
      return map(this.operatorLocations,
        location => (
          {
            ...location,
            value: location.address || location.name,
            description: location.name || location.address,
            label: location.name || location.address,
            icon: OperatorStationIcon,
          }
        ));
    },

    isSaveButtonDisabled() {
      return this.isLoading || !this.areLocationsUpdated;
    },

    emptyPreferredLocations() {
      const locations = pickBy(this.preferredLocations, location => isEmpty(location.address_street));
      const emptyPreferredLocations = mapValues(locations, (location, type) => ({ ...location, type }));

      return sortBy(emptyPreferredLocations, location => this.customLocationsOrder[location.type]);
    },

    filledPreferredLocations() {
      const locations = omitBy(this.preferredLocations, location => isEmpty(location.address_street));
      const filledPreferredLocations = mapValues(locations, (location, type) => ({ ...location, type }));

      return sortBy(filledPreferredLocations, location => this.customLocationsOrder[location.type]);
    },

    currentSavedLocations() {
      return omitBy(this.currentFavouriteLocations, location => isEmpty(location.address_street));
    },

    labels() {
      return {
        custom: this.$t('modal.preferredLocations.custom'),
        home: this.$t('modal.preferredLocations.home'),
        work: this.$t('modal.preferredLocations.work'),
        missingAddressComponent: this.$t('modal.preferredLocations.missing_address_component'),
      };
    },
  },

  watch: {
    currentFavouriteLocations: {
      handler() {
        this.updatePreferredLocations();
        this.syncLocationsStatus();
      },
      deep: true,
    },
  },

  async created() {
    this.inputIcons = {
      home: this.ICONS.home,
      work: this.ICONS.company,
      custom: this.ICONS.heart,
    };
    this.customLocationsOrder = { home: 0, work: 1, custom: 2 };
    this.addressComponents = [
      'route',
      'administrative_area_level_1',
      'country_code',
    ];

    this.errorMessages = {
      generic: this.$t('modal.preferredLocations.invalid_location_error'),
      required: this.$t('modal.preferredLocations.input_required'),
      notSelectedFromList: this.$t('modal.preferredLocations.select_item_from_list'),
    };
  },

  methods: {
    size,
    closeModal() {
      this.$emit('on:close-modal', false);
      this.updatePreferredLocations();
      this.syncLocationsStatus();
      this.trackSegment({
        name: SEGMENT_EVENTS.CLOSE_PREFERRED_LOCATIONS,
        data: {
          currentSavedLocations: size(this.currentSavedLocations),
        },
      });
    },

    clearPreferredLocation(type) {
      this.preferredLocations[type].address_street = '';
      this.syncLocationsStatus();
    },

    updatePreferredLocations() {
      this.preferredLocations = cloneDeep(this.currentFavouriteLocations);
    },

    changeLocation(inputValue, type) {
      this.preferredLocations[type] = { ...this.preferredLocations[type], address_street: get(inputValue, 'address') };
      this.syncLocationsStatus();
    },

    syncLocationsStatus() {
      this.areLocationsUpdated = !isEqual(this.preferredLocations, this.currentFavouriteLocations);
    },

    saveFavoriteLocation() {
      this.$emit('save-preferred-locations', this.preferredLocations);
      this.trackSegment({
        name: SEGMENT_EVENTS.UPDATE_PREFERRED_LOCATIONS,
        data: {
          currentSavedLocations: size(this.currentSavedLocations),
          newSavedLocations: size(this.filledPreferredLocations),
        },
      });
    },
  },
};
</script>
